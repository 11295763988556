import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { Box, Container, Grid, Typography } from '@material-ui/core';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import ContentContainerContact from './contentContainerContact';
import { useContactStyles } from './styles';

const GbiBridged = () => {
  const { backgroundImageCallCenter } = useStaticQuery(
    graphql`
      query {
        backgroundImageCallCenter: file(relativePath: { eq: "call-center-office.png" }) {
          childImageSharp {
            gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
          }
        }
      }
    `,
  );

  const pluginImage = getImage(backgroundImageCallCenter);
  const { t } = useTranslation();
  const classes = useContactStyles();
  return (
    <>
      <BgImage image={pluginImage} className="masthead2 imagesBlur">
        <Grid container spacing={2} className={classes.headImagesTextPosition}>
          <Grid item lg={6} md={6} xs={12}>
            <Box className={classes.boxImagesHead}>
              <Typography className={classes.siteTitle}>{t('trans.formImagesHead')}</Typography>

              <Box mt={1.5}>
                <Typography className={classes.siteTitleContent}>{t('trans.formImagesHeadContent')}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </BgImage>

      <Container id="how-to-register">
        <Box mt={9}>
          <Typography className={classes.headheadingSite}>{t('trans.contact')}</Typography>
          <Typography className={classes.headheadingContent}>{t('trans.contactContent')}</Typography>
        </Box>
        <Box className="masthead2">
          <Grid container spacing={2} className={classes.headImagesTextPosition}>
            <Grid item lg={12} md={12} xs={12}>
              <ContentContainerContact />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default GbiBridged;
