import React from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { Box, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';

import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import ContactSendButton from '../Button/contactSend';
import { useContactStyles } from './styles';

const LinkText = styled(Link)`
  display: inline-block;
  z-index: -2;
  text-decoration: none;
  color: #ea5e2a;
  letter-spacing: 0.03rem;

  &:hover {
    color: #ea5e2a;
    text-decoration: none;
    letter-spacing: 0.03rem;
  }
  &:after {
    opacity: 0;
    text-decoration: none;
  }
  &:hover&:after {
    opacity: 0;
    text-decoration: none;
  }
`;

const ContentContainerContact = () => {
  const { t, i18n } = useTranslation();

  const classes = useContactStyles();
  const ControlCheckbox = withStyles({
    root: {
      color: '#9e9e9e',

      '&$checked': {
        color: '#032963',
      },
    },
    checked: {},
    // eslint-disable-next-line react/jsx-props-no-spreading
  })((props) => <Checkbox color="default" {...props} />);

  const initialState = {
    checkedPrivacyPolicy: false,
    contactName: '',
    contactEmail: '',
    contactMessage: '',
    contactReference: '',
    contactOrganisation: '',
  };
  const [formValues, setFormValues] = React.useState(initialState);

  // eslint-disable-next-line no-unused-vars
  const [formState, setFormState] = React.useState({
    loading: false,
    error: false,
    msg: '',
  });

  const handleChange = (event) => {
    if (event.target.name === 'checkedPrivacyPolicy') {
      setFormValues({ ...formValues, [event.target.name]: event.target.checked });
    } else {
      setFormValues({ ...formValues, [event.target.name]: event.target.value });
    }
  };

  const handleSuccess = () => {
    setFormState({ loading: false, error: false });
    setFormValues(initialState);
  };

  const handleError = (msg) => {
    setFormState({ loading: false, error: true, msg });
  };

  const handleSubmit = (e) => {
    const data = { ...formValues, language: i18n.language };

    axios.post(process.env.GATSBY_API_CONTACT_FORM, JSON.stringify(data)).then((response) => {
      if (response.status !== 200) {
        handleError();
      } else {
        handleSuccess();

        const dataLayer = window.dataLayer || [];

        dataLayer.push({
          event: 'form_submit_contact',
        });
      }
    });
    e.preventDefault();
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={4} md={4} xs={12}>
          <Typography className={classes.contentBold}>{t('trans.adressHead')}</Typography>
          <Box className={classes.adressBox}>
            <Typography className={classes.checkboxContent}>{t('trans.adressContent')}</Typography>
            <Typography className={classes.adressBold}>{t('trans.adressCompany')}</Typography>

            <Typography className={classes.adress}>{t('trans.adressStreet')}</Typography>

            <Typography className={classes.adress}>{t('trans.adressLocation')}</Typography>
            <Typography className={classes.adress}>{t('trans.adressCountry')}</Typography>
          </Box>
        </Grid>
        <Grid item lg={8} md={8} xs={12}>
          <Typography className={classes.contentBold}>{t('trans.formHead')}</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              className={classes.textField}
              required
              onChange={handleChange}
              name="contactName"
              id="contactName"
              label={t('trans.formName')}
              style={{ marginTop: 8, maxWidth: '100%' }}
              fullWidth
              margin="normal"
              variant="outlined"
              value={formValues.contactName}
              inputProps={{
                style: {
                  letterSpacing: '0.03rem',
                  fontSize: 18,
                  height: 16,
                  background: '#efefef',
                },
              }}
            />

            <TextField
              className={classes.textField}
              required
              onChange={handleChange}
              value={formValues.contactEmail}
              name="contactEmail"
              id="contactEmail"
              label={t('trans.formEmail')}
              style={{ marginTop: 8, maxWidth: '100%' }}
              fullWidth
              type="email"
              margin="normal"
              variant="outlined"
              inputProps={{
                style: {
                  letterSpacing: '0.03rem',
                  fontSize: 18,
                  height: 16,
                  background: '#efefef',
                },
              }}
            />
            <TextField
              className={classes.textField}
              required
              value={formValues.contactOrganisation}
              onChange={handleChange}
              name="contactOrganisation"
              id="contactOrganisation"
              label={t('trans.formCompany')}
              style={{ marginTop: 8, maxWidth: '100%' }}
              fullWidth
              margin="normal"
              variant="outlined"
              inputProps={{
                letterSpacing: '0.03rem',
                style: { fontSize: 18, height: 16, background: '#efefef' },
              }}
            />

            <TextField
              className={classes.textField}
              required
              value={formValues.contactReference}
              id="contactReference"
              name="contactReference"
              onChange={handleChange}
              label={t('trans.formReference')}
              style={{ marginTop: 8, maxWidth: '100%' }}
              fullWidth
              margin="normal"
              variant="outlined"
              inputProps={{
                letterSpacing: '0.03rem',
                style: { fontSize: 18, height: 16, background: '#efefef' },
              }}
            />
            <TextField
              className={classes.textField}
              required
              name="contactMessage"
              value={formValues.contactMessage}
              id="contactMessage"
              label={t('trans.formNews')}
              onChange={handleChange}
              multiline
              rowsMax={15}
              style={{ marginTop: 8, maxWidth: '100%', background: '#efefef' }}
              fullWidth
              margin="normal"
              variant="outlined"
              inputProps={{
                style: {
                  letterSpacing: '0.03rem',
                  fontSize: 18,
                  height: 150,
                  background: '#efefef',
                },
              }}
            />
            <Typography className={classes.contentMandatoryFields}>{t('trans.formInfoMandatoryFields')}</Typography>
            <Box className={classes.boxContentInformation}>
              <Typography className={classes.checkboxContent}>
                <FormControlLabel
                  required
                  onChange={handleChange}
                  control={
                    <ControlCheckbox
                      checked={formValues.checkedPrivacyPolicy}
                      onChange={handleChange}
                      name="checkedPrivacyPolicy"
                    />
                  }
                  label={t('trans.formCheck')}
                />
              </Typography>
            </Box>
            <Box className={classes.sendBox}>
              <ContactSendButton />
            </Box>
          </form>
        </Grid>

        <Grid item lg={12} md={12} xs={12}>
          <Box mb={5}>
            <Typography className={classes.checkboxContent}>
              <b>{t('trans.formInfo')}</b> {t('trans.formDSVGO')}{' '}
              <LinkText to="/privacy-policy">{t('trans.formDSVGOLink')}</LinkText>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ContentContainerContact;
